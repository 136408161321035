import { GoTriangleUp } from "react-icons/go"

export default function GoUp() {
    const srcollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    };

    return (
        <button className="d-flex d-md-none justify-content-center align-items-center btn rounded-circle" style={{
            backgroundColor: "#FF7700",
            width: "48px",
            height: "48px",
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            zIndex: 10
        }} onClick={srcollUp}>
            <GoTriangleUp size={32} color={'white'} />
        </button>
    );
}