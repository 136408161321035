import style from "./Presentamos.module.scss";
import Link from 'next/link';


export default function Presentamos() {
    return (
        <div className="container py-5" style={{marginTop: '8px'}}>
            <div className="row">
                <div className="col-md-4 order-1 order-md-0">
                    <div className={style.w__container}>
                        <div className={`${style.stroke} ${style.stroke__1}`}>
                            <div className={style.line__1}></div>
                            <div className={style.line__2}></div>
                        </div>
                        <div className={`${style.stroke} ${style.stroke__2}`}>
                            <div className={style.line__1}></div>
                            <div className={style.line__2}></div>
                        </div>
                        <div className={`${style.stroke} ${style.stroke__3}`}>
                            <div className={style.line__1}></div>
                            <div className={style.line__2}></div>
                            <div className={style.line__3}></div>
                        </div>
                        <div className={`${style.stroke} ${style.stroke__4}`}>
                            <div className={style.line__1}></div>
                            <div className={style.line__2}></div>
                        </div>
                        <div className={`${style.stroke} ${style.stroke__5}`}>
                            <div className={style.line__1}></div>
                            <div className={style.line__2}></div>
                        </div>
                        <div className={`${style.stroke} ${style.stroke__6}`}>
                            <div className={style.line__1}></div>
                            <div className={style.line__2}></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 order-0 order-md-1 px-4 px-md-0">
                    <h1 className="fs-header fw-bold mb-4 arial-black text-black">NOS PRESENTA<span className="text-orange">M</span>OS</h1>
                    <p>
                        Somos Where is my brand? (<span className="text-orange">WIMB</span> para los amigos), una agencia tradicional con ADN digital.
                        Somos exploradores del nuevo mundo y combinamos lo mejor de la publicidad análoga con lo más creativo de la vida online.
                        Así, ayudamos a las marcas a descubrir su verdadera identidad para que alcancen sus objetivos.
                    </p>
                    <Link href='/nosotros'>
                        <button className="black-to-orange-btn">
                            CONOCER MÁS
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}