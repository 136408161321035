import { useState } from 'react';
import s from './Cintilla.module.scss';

export default function Cintilla({ data }) {
    let [closed, setclose] = useState(false);

    let closeBar = () => {
        setclose(true);
    }

    return (
        <div>
            {(closed == false && data != null) ?
                <div className={`${s['cintilla-container']} orange`}>
                    {data?.aviso}
                    <span className="pointer" onClick={closeBar}><strong>X</strong></span>
                </div>
                : 
               <div></div>
            }
        </div>
    )
}