import style from "./Testimonios.module.scss";
import config from '../../../config/config';

export default function Testimonios({ data }) {
    return (
        <div className={style.main__container}>
            <div className={style.bg__upper}></div>
            <div className={style.bg__lower}></div>
            <div className={style.carousel__container}>
                <h1 className="fs-header fw-bold text-white text-center arial-black">
                    TESTI<span className="text-orange">M</span>ONIOS
                </h1>
                <div id="carouselTestimonios" className="carousel slide position-relative" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {data.map((t, i) => (
                            <div key={i} className={`carousel-item w-100 ${i == 0 && 'active'}`}>
                                <div className={style.custom__slide}>
                                    <div className={style.text__container}>
                                        <img src={config().assetUrl + t.logo?.url} alt="logo" className={style.logo} />
                                        <h2 className="fs-3 text-orange text-center mb-4">{t.nombre}</h2>
                                        <div className="fs-6 fw-normal" dangerouslySetInnerHTML={{ __html: t?.testimonio }}></div>
                                    </div>
                                    <div className={style.img__container}>
                                        <div className={style.overlay}>
                                            {/* <img src={config().assetUrl + t.logo?.url} alt="logo" className={style.overlay__logo} /> */}
                                        </div>
                                        <img src={config().assetUrl + t.banner?.url} alt="photo" className={style.photo} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        className={`carousel-control-prev ${style.prev__button}`}
                        type="button" data-bs-target="#carouselTestimonios"
                        data-bs-slide="prev">
                        <img className={style.icon} alt="prev" />
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className={`carousel-control-next ${style.next__button}`}
                        type="button"
                        data-bs-target="#carouselTestimonios"
                        data-bs-slide="next">
                        <img className={style.icon} alt="next" />
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
}