import Head from "next/head";
import "swiper/css/bundle";
import config from '../config/config';

//custom components
import Navbar from "../components/Navbar/Navbar";
import Cintilla from "../components/Cintilla/Cintilla";
import Hero from "../components/Home/Hero/Hero";
import Projects from "../components/Home/Projects/Projects";
import Presentamos from "../components/Home/Presentamos/Presentamos";
import Servicios from "../components/Home/Servicios/Servicios";
import Testimonios from "../components/Home/Testimonios/Testimonios";
import FormContacto from "../components/FormContacto/FormContacto";
import Abracadabra from "../components/Home/Abracadabra/Abracadabra";
import Footer from "../components/Footer/Footer";
import GoUp from "../components/GoUp/GoUp";

export default function Home({ data }) {
  let hasNews = false;
  if (data == null) {
    hasNews = false;
  }
  else {
    hasNews = true;
  }

  return (
    <div className="bg-gray">
      <Head>
        <title>Inicio | Where is my brand?</title>
        <meta name="description" content="WIMB: Agencia tradicional con ADN Digital" />
        <link rel="icon" href="/favicon.png" />
      </Head>
      {hasNews ? <Cintilla data={data?.avisos} /> : null}
      <Navbar hasNews={hasNews} />
      <Hero />
      <Projects />
      <Presentamos />
      <Servicios />
      <Testimonios data={data?.testimonios} />
      <Abracadabra />
      <FormContacto decorated={true} withPhoto={false} referencia="home" />
      <Footer />
      <GoUp />
    </div>
  )
}

export async function getStaticProps() {
  const res = await fetch(config().apiUrl + '/avisos');
  const resJson = await res.json();

  const resTest = await fetch(config().apiUrl + '/testimonios?populate=*');
  const resJsonTest = await resTest.json();

  let data = null;

  if (resJson?.data.length > 0) {
    data = resJson?.data[0];
  }

  return {
    props: {
      data: {
        avisos: data,
        testimonios: resJsonTest?.data
      }
    },
    revalidate: 300
  }
}