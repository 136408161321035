import style from "./Projects.module.scss";
import bgAreca from "../../../public/img/home/areca.webp";
import bgMet from "../../../public/img/home/MET.webp";
import bgIntegra from "../../../public/img/home/Integra.webp";
import Link from 'next/link';

export default function Projects() {
    const projects = [
        {
            name: "Mercado Metropolitano",
            type: "project",
            img: bgMet,
            link: "/portafolio/mercado-metropolitano"
        },
        {
            name: "areca",
            type: "project",
            img: bgAreca,
            link: "/portafolio/areca-valley"
        },
        {
            name: "portafolio",
            type: "ad",
            text: "Ya buscamos y encontramos a estas marcas. ¿Quieres ver los resultados?",
            bgColor: "#FF7700",
            textColor: "#FFFFFF",
            link: "/portafolio"
        },
        {
            name: "Integra Leon",
            type: "project",
            img: bgIntegra,
            link: "/portafolio/integra"
        },
    ];

    return (
        <div className="container" style={{marginTop: '52px'}}>
            <div className={style['projects-grid']}>
                {projects.map((p, i) => (
                    p.type == "project"
                        ?
                        <Link href={p.link} key={p.name}>
                            <div className={`pointer ${style['project']} ${style[`project-${i + 1}`]} ${style['with-overlay']}`}
                                style={{
                                    backgroundImage: `url(${p.img?.src})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "cover"
                                }}
                            ></div>
                        </Link>

                        : <div
                            key={p.name}
                            className={`${style['project']} ${style[`project-${i + 1}`]} text-center d-flex flex-column justify-content-center px-3`}
                            style={{
                                backgroundColor: p.bgColor
                            }}
                        >
                            <h2 className="arial-black" style={{
                                color: p.textColor,
                            }}>
                                Ya buscamos y encontramos a estas marcas. ¿Quieres ver los resultados?
                            </h2>
                            <Link href='/portafolio'>
                                <button className="white-to-black-btn mt-4 mx-auto">
                                    VAMOS
                                </button>
                            </Link>
                        </div>
                ))}
            </div>
        </div>
    );
}