import style from "./Abracadabra.module.scss";

import diagrama from "../../../public/img/home/Diagrama.webp";
import diagramaMobile from "../../../public/img/home/Diagrama-mobile.webp";

export default function Abracadabra() {
    return (
        <div className="container postition-relative my-5" style={{ marginTop: '68px' }}>
            <h1 className="fs-headeer text-center fw-bold arial-black text-black">
                ABRACADA<span className="span text-orange">B</span>RA
            </h1>
            <p className={`text-center w-75 mx-auto ${style.message}`}>
                Así trabajaremos contigo en Where is my brand?
            </p>
            <img src={diagrama?.src} alt="diagrama" className={style.diagram} />
            <img src={diagramaMobile?.src} alt="diagrama" className={style.diagram__mobile} />
        </div>
    );
}