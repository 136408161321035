import { useRouter } from "next/router";
import style from "./Servicios.module.scss";

export default function Servicios() {
    const router = useRouter();
    const slides = [
        {
            title: "Strategy",
            description: "Utilizamos nuestra experiencia, sensibilidad y capacidad de análisis, observación y conocimiento del mercado para definir las acciones clave que llevarán a nuestros clientes a posicionarse en su sector y alcanzar sus objetivos de negocio.<br/><br/>"
        },
        {
            title: "Branding",
            description: "Nuestro mayor valor añadido. Nos enfocamos en construir una identidad de marca sólida, auténtica y con la posibilidad de trascender en el tiempo, así como una estrategia de comunicación que ayude a la marca a conectar con su audiencia de manera profunda y duradera.<br/><br/>"
        },
        {
            title: "Web & System Dev",
            description: "Desarrollamos sitios web y sistemas personalizados que se adaptan a las necesidades únicas de nuestros clientes. Desde la planificación y el diseño hasta la implementación y la prueba, garantizamos un producto final que cumplirá todos los requerimientos."
        },
        {
            title: "Social Media",
            description: "Maximizamos tu presencia online a través de una estrategia de comunicación que impacta la creación de contenidos en redes sociales. Así, no solo logramos una mayor visibilidad, sino que aseguramos que cada acción esté alineada con los objetivos de marketing y con la identidad de la marca.<br/><br/>"
        },
        {
            title: "Paid Media",
            description: "Nos enfocamos en entender tu audiencia para la generación de tus campañas publicitarias en línea, usamos una combinación de estrategias de publicidad pagada en diversas plataformas para lograr tus objetivos de negocio.<br/><br/>"
        },
        {
            title: "Audiovisual Production",
            description: "Creamos contenido audiovisual impactante para promocionar tu marca o producto. Desde concepto hasta producción, nos aseguramos de que el resultado final se adapte a tus necesidades y consiga tus objetivos de negocio en tiempo y forma."
        }
    ];

    const goToServicios = () => {
        router.push('/servicios');
    }

    return (
        <div className="container py-5" style={{ marginTop: '-16px' }}>
            <h1 className="fs-header fw-bold ms-2 d-block d-md-none arial-black text-black">SERV<span className="text-orange">I</span>CIOS</h1>
            <div className="row">
                <div className="col-md-6 order-1 order-md-0 px-4">
                    <div className="row mt-4 mt-md-0">
                        <h2 className="fs-header fw-bold ms-2 d-none d-md-block arial-black text-black">SERV<span className="text-orange">I</span>CIOS</h2>
                        {slides.map((s, j) => (
                            <div key={j} className={`col-6 col-md-4 mt-2 pointer`} data-bs-target="#carouselServicios" data-bs-slide-to={j}>
                                <h1 className="text-orange fs-4 arial-black">0{j + 1}</h1>
                                <h2 className={`fs-5 fw-normal ${style['hover-orange']}`}>{s.title}</h2>
                            </div>
                        ))}
                    </div>
                    <button className="black-to-orange-btn mt-4" onClick={goToServicios}>IR A SERVICIOS</button>
                </div>
                <div className="col-md-6 order-0 order-md-1">
                    <div id="carouselServicios" className="carousel slide position-relative" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {slides.map((s, i) => (
                                <div key={i} className={`carousel-item w-100 ${i == 0 && 'active'}`}>
                                    <div className={style['info-slide']}>
                                        <h1 className="text-orange fs-2 arial-black text-orange">0{i + 1}</h1>
                                        <h2 className="fs-3 mb-4 arial-black text-black">{s.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: s?.description }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button
                            className="carousel-control-prev"
                            type="button" data-bs-target="#carouselServicios"
                            data-bs-slide="prev"
                            style={{
                                position: "absolute",
                                bottom: -17 + "rem",
                                left: "75%"
                            }} >
                            <img src="/svg/black-arrow-left.svg" alt="prev" style={{ width: '2rem' }} />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target="#carouselServicios"
                            data-bs-slide="next"
                            style={{
                                position: "absolute",
                                bottom: -17 + "rem",
                                right: 0
                            }}>
                            <img src="/svg/black-arrow-right.svg" alt="prev" style={{ width: '2rem' }} />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}